



















































import Confirm from '@/components/core/Confirm.vue';
import WelcomeMixin from '@/mixins/welcome-mixin';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  mixins: [WelcomeMixin]
})
export default class Onboarding extends Vue {
    valid = false;
    loading!: boolean;
    hasErrorMsg!: boolean;
    errorMsg!: string;
    regToken!: any;
    joinCode!: string;
    accountExists!: boolean;
    hasJoinCode!: boolean;
    yearId!: any;
    schoolId!: any;
    teacherId!: any;
    subjectIds!: Array<any>;
    hasStudentJoinCode!: boolean;
    userType = 'T'

    signUp!: (user: any) => Promise<any>;
    fru!: (condition: boolean, value: any) => any;

    get userTypes() {
      return [
        {
          value: 'A',
          icon: 'fal fa-user-gear',
          text: this.$i18n.t('adminLabel')
        },
        {
          value: 'T',
          icon: 'fal fa-chalkboard-user',
          text: this.$i18n.t('teacherLabel')
        },
        {
          value: 'S',
          icon: 'fal fa-book-open-reader',
          text: this.$i18n.t('studentLabel')
        }
      ]
    }

    $refs!: {
        confirm: Confirm;
        onboardingForm: Vue & { validate: () => boolean; resetValidation: () => void };
    };

    validate() {
      return this.$refs.onboardingForm.validate();
    }

    get routeSource() {
      return this.$route.query.src;
    }

    get routeRegistrationToken() {
      return this.$route.query.registration_token;
    }

    get cancelPath() {
      return this.routeSource === 'signup' ? '/auth/signup' : '/auth/login';
    }

    doSignUp() {
      this.errorMsg = '';
      if (this.validate()) {
        this.loading = true;
        const registerStudent = this.fru(this.hasStudentJoinCode, true) && this.userType === 'S';
        const params = {
          regToken: this.regToken,
          registerStudent,
          connect: this.fru(this.accountExists, true),
          token: this.fru(this.hasJoinCode, this.joinCode),
          schoolId: this.schoolId,
          subjectIds: this.subjectIds,
          yearId: this.yearId,
          teacherId: this.teacherId
        };
        this.signUp(params)
          .then(resp => {
            if (registerStudent) {
              return this.$router.replace({ path: '/plans' });
            } else {
              return this.$router.replace({
                path: '/join',
                query: {
                  token: resp.data.idToken,
                  referrer: window.location.origin
                }
              });
            }
          })
          .catch(error => {
            this.errorMsg = error.message;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }

    mounted() {
      if (CommonUtils.hasNoText(this.routeSource)) {
        this.errorMsg = 'It seems like you don’t have an account with us yet.'
      }
      this.regToken = this.routeRegistrationToken;
    }
}
