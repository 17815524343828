var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    {
      ref: "onboardingForm",
      class: { "mt-10": _vm.$vuetify.breakpoint.smAndDown },
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.doSignUp.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-toolbar",
                { staticClass: "text-h4 text-center", attrs: { flat: "" } },
                [
                  _c("div", { staticClass: "justify-center fill-width" }, [
                    _c("img", {
                      attrs: {
                        src: "https://cdn.planbook.com/images/planbook-logo-blue.svg",
                        alt: _vm.$t("app.name"),
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.hasInfoMsg
            ? _c(
                "div",
                {
                  staticClass:
                    "my-2 text-center font-weight-light font-italic info-message",
                },
                [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.infoMsg) } }),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "d-inline-block ml-2",
                      attrs: {
                        label: _vm.$t("cancelLabel"),
                        icon: "",
                        "x-small": "",
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.doReset.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("fal fa-times"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.hasErrorMsg
            ? _c("div", {
                staticClass:
                  "my-2 text-center font-weight-light font-italic error-message",
                domProps: { innerHTML: _vm._s(_vm.errorMsg) },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "px-10 pb-2 pt-8" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-8 primary--text",
                  class: { "text-left": _vm.$vuetify.breakpoint.smAndDown },
                },
                [_vm._v(_vm._s(_vm.$t("finishSettingUpAccountLabel")))]
              ),
              _c("v-select", {
                attrs: {
                  items: _vm.userTypes,
                  label: _vm.$t("iAmALabel"),
                  outlined: "",
                  loading: _vm.loading,
                  readonly: _vm.hasAnyJoinCode || _vm.loading,
                },
                model: {
                  value: _vm.userType,
                  callback: function ($$v) {
                    _vm.userType = $$v
                  },
                  expression: "userType",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "px-10 pb-8 pt-0 justify-center",
              class: {
                "welcome-card-actions": _vm.$vuetify.breakpoint.mdAndUp,
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-10 mb-3",
                  attrs: {
                    depressed: "",
                    color: "error",
                    dark: "",
                    width: "175px",
                    type: "submit",
                    loading: _vm.loading,
                  },
                },
                [_vm._v(_vm._s(_vm.$t("continueLabel")))]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-0 mb-3",
                  attrs: {
                    depressed: "",
                    color: "info",
                    dark: "",
                    width: "175px",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.doCancel },
                },
                [_vm._v(_vm._s(_vm.$t("cancelLabel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }