
import Confirm from '@/components/core/Confirm.vue';
import WelcomeMixin from '@/mixins/welcome-mixin';
import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  mixins: [WelcomeMixin]
})
export default class Onboarding extends Vue {
    valid = false;
    loading!: boolean;
    hasErrorMsg!: boolean;
    errorMsg!: string;
    regToken!: any;
    joinCode!: string;
    accountExists!: boolean;
    hasJoinCode!: boolean;
    hasInfoMsg!: boolean;
    joinData!: any;
    infoMsg!: string;
    authRedirectUri!: string;
    yearId!: any;
    schoolId!: any;
    teacherId!: any;
    allowNewStudentAccounts!: boolean;
    subjectIds!: Array<any>;
    hasStudentJoinCode!: boolean;
    userType!: string;
    isValidStudentJoinCode!: boolean;
    localStudentJoinData!: any;
    studentJoinCode!: string;
    email = '';

    signUp!: (user: any) => Promise<any>;
    fru!: (condition: boolean, value: any) => any;
    doReset!: () => void;
    loadJoinData!: () => Promise<any>;

    get userTypes() {
      const userTypes = [
        {
          value: 'A',
          icon: 'fal fa-user-gear',
          text: this.$i18n.t('adminLabel')
        },
        {
          value: 'T',
          icon: 'fal fa-chalkboard-user',
          text: this.$i18n.t('teacherLabel')
        },
        {
          value: 'S',
          icon: 'fal fa-book-open-reader',
          text: this.$i18n.t('studentLabel')
        }
      ];
      if (this.userType === 'D') {
        return userTypes.concat([
          {
            value: 'D',
            icon: 'fal fa-user-gear',
            text: this.$i18n.t('districtAdminLabel')
          }
        ]);
      } else if (this.userType === 'B') {
        return userTypes.concat([
          {
            value: 'B',
            icon: 'fal fa-chalkboard-user',
            text: this.$i18n.t('dualLabel')
          }
        ]);
      } else if (this.userType === 'M') {
        return userTypes.concat([
          {
            value: 'M',
            icon: 'fal fa-user-gear',
            text: this.$i18n.t('managerLabel')
          }
        ]);
      }
      return userTypes;
    }

    $refs!: {
        confirm: Confirm;
        onboardingForm: Vue & { validate: () => boolean; resetValidation: () => void };
    };

    validate() {
      return this.$refs.onboardingForm.validate();
    }

    get hasRegToken() {
      return CommonUtils.hasText(this.regToken);
    }

    get routeSource() {
      return this.$route.query.src;
    }

    get routeRegistrationToken() {
      return this.$route.query.reg_token;
    }

    get isMobileRedirect() {
      return CommonUtils.hasText(this.authRedirectUri) && (this.authRedirectUri.endsWith('/success/ios') || this.authRedirectUri.endsWith('/success/android'));
    }

    get routeRedirectUri(): string {
      return this.$route.query.redirect_uri as string;
    }

    get cancelPath() {
      return this.routeSource === 'signup' ? '/auth/signup' : '/auth/login';
    }

    get hasAnyJoinCode() {
      return this.hasStudentJoinCode || this.hasJoinCode;
    }

    get hasJoinCodeAndRegToken() {
      return this.hasRegToken && this.hasAnyJoinCode;
    }

    doSignUp() {
      this.errorMsg = '';
      if (this.validate()) {
        if (this.userType !== 'S' || this.hasStudentJoinCode) {
          this.loading = true;
          const registerStudent = this.fru(this.hasStudentJoinCode, true) && this.userType === 'S';
          const params = {
            regToken: this.regToken,
            registerStudent,
            connect: this.fru(this.accountExists, true),
            token: this.fru(this.hasJoinCode, this.joinCode),
            schoolId: this.schoolId,
            subjectIds: this.subjectIds,
            yearId: this.yearId,
            teacherId: this.teacherId,
            userType: this.userType
          };
          this.signUp(params)
            .then(resp => {
              if (this.isMobileRedirect) {
                window.location.href = this.authRedirectUri + '?token=' + resp.data.idToken;
                return Promise.resolve({});
              } else if (registerStudent) {
                return this.$router.replace({ path: '/plans' });
              } else {
                const currentYearId = +resp.data?.currentYearId;
                if (currentYearId > 0) {
                  return this.$router.replace({ path: '/plans', query: { newUser: 'true' } });
                } else {
                  return this.$router.replace({
                    path: '/join',
                    query: {
                      token: resp.data.idToken,
                      referrer: window.location.origin
                    }
                  });
                }
              }
            })
            .catch(error => {
              this.errorMsg = error.message;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return this.$router.replace({
            path: '/auth/student-codes'
          });
        }
      }
    }

    doCancel() {
      this.doReset();
      if (this.isMobileRedirect) {
        window.location.href = this.authRedirectUri;
      } else {
        return this.$router.replace({ path: this.cancelPath });
      }
    }

    mounted() {
      if (CommonUtils.hasNoText(this.routeSource)) {
        this.errorMsg = this.$t('noAccountErrMsg') as string;
      }
      this.userType = this.hasStudentJoinCode ? 'S' : 'T';
      if (CommonUtils.hasText(this.routeRegistrationToken)) {
        this.regToken = this.routeRegistrationToken;
      }
      if (CommonUtils.hasText(this.routeRedirectUri)) {
        this.authRedirectUri = this.routeRedirectUri;
      }
      if (this.hasAnyJoinCode) {
        this.loadJoinData().then((resp) => {
          if (this.hasJoinCode) {
            if (CommonUtils.hasText(this.joinData.emailAddress)) {
              this.email = this.joinData.emailAddress;
            }
            this.infoMsg = this.$t('welcomeMsg100', {
              name: this.joinData.schoolName,
              action: (this.$t('continueLabel') as string).toLowerCase()
            }) as string;
          } else if (this.hasStudentJoinCode) {
            const data = resp.data || {};
            const classNames: any = [];
            if (data.count > 0) {
              for (const i in data.className) {
                classNames.push(`<span style="font-weight: bold;">${data.className[i]} (${data.teacherEmailAddress[i]})</span>`);
              }
            }
            if (this.isValidStudentJoinCode) {
              if (this.allowNewStudentAccounts) {
                this.infoMsg = this.$t('welcomeMsg101', {
                  codes: classNames.join(', '),
                  action: (this.$t('continueLabel') as string).toLowerCase()
                }) as string;
              } else {
                this.errorMsg = this.$t('allowStudentsErrMsg') as string;
              }
            } else {
              this.errorMsg = this.$t('invalidStudentJoinCode') as string;
              this.$store.commit('welcome/reset', null, { root: true });
            }
          }
          return Promise.resolve();
        });
      }
    }
}
